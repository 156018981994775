import './App.css';
import { useState, useMemo, useEffect, useRef } from 'react';
import { decodeString } from '../encoding/index.mjs';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, Avatar, ChatContainer, MessageList, Message, MessageSeparator, MessageGroup, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import conversation from '../data/convo.json';

const App = () => {
    const maxDelay = 5000;
    const delayIncrement = 200;

    const [count, setCount] = useState(0);
    const [delay, setDelay] = useState(2000);

    const [sender, receiver] = conversation.participants;
    const messageCount = conversation.messages.length;

    // get refs for setTikmeout to use so it uses current values instead of those in the closure
    const delayRef = useRef(delay);
    delayRef.current = delay;
    const countRef = useRef(count);
    countRef.current = count;


    // timer to iterate the count through the messages
    useEffect(() => {
        const timer = setTimeout(() => {
            if (count < messageCount) {
                setCount(countRef.current + 1)
            }
        }, delayRef.current);
        return () => clearTimeout(timer);
    }, [countRef, count, setCount, messageCount, delayRef]);

    // Get list of messages when count or receiver changes
    const messages = useMemo(
        () => {
            let activeMessages = [];
            let lastDate = '';
            let messageGroup = [];

            for (let i = 0; i < count; i++) {
                const message = conversation.messages[i];
                let direction = (message.sender === receiver.name) ? 'incoming' : 'outgoing';
                let newDate = message.sentTime.substring(0, 9);

                messageGroup.push(message);

                // add a separator for new dates
                if (lastDate !== newDate) {
                    activeMessages.push(<MessageSeparator key={`separator-${i}`}>{message.sentTime}</MessageSeparator>);
                }

                // if the next sender isn't the same, or we're at the end of the list, end this message group
                if ((i + 1) === count || message.sender !== conversation.messages[i + 1].sender) {
                    let groupMessages = messageGroup.map((groupMessage, index) => {
                        return <Message key={`index-${i}-${index}`} model={{
                            message: decodeString(groupMessage.message),
                            sentTime: groupMessage.timestamp,
                            sender: decodeString(groupMessage.sender)
                        }} ></Message>;
                    });

                    activeMessages.push(<MessageGroup key={`group-${i}`} direction={direction} sender={decodeString(message.sender)} sentTime={message.sentTime}>
                        <Avatar src={(direction === 'incoming') ? receiver.icon : sender.icon} name={decodeString(message.sender)} />
                        <MessageGroup.Messages>
                            {groupMessages}
                        </MessageGroup.Messages>
                    </MessageGroup>);

                    messageGroup = [];
                }

                lastDate = newDate;
            }

            return activeMessages;
        },
        [count, sender, receiver]
    );

    // get the TypingIndicator component if there are more messages to come
    const typingIndicator = useMemo(
        () => {
            if (count < messageCount) {
                return <TypingIndicator content={`${decodeString(conversation.messages[count].sender)} is typing`} />;
            }

            return null;
        },
        [count, messageCount]
    );

    const resetCount = () => {
        setCount(0);
    }

    const jumpToEnd = () => {
        setCount(messageCount);
    }

    const goSlower = () => {
        if (delay < maxDelay) {
            setDelay(delay + delayIncrement);
        }
    }

    const goFaster = () => {
        if (delay > delayIncrement) {
            setDelay(delay - delayIncrement);
        }
    }

    return <div className="App">
        <div className="CommandBar">           
            <input className="inputButton" type="image" id="resetButton" alt="Reset" src="./up-icon.png" onClick={resetCount} />
            <input className="inputButton" type="image" id="slowerButton" alt="Reset" src="./slower-icon.png" onClick={goSlower} />
            <input className="inputButton" type="image" id="fasterButton" alt="Reset" src="./faster-icon.png" onClick={goFaster} />
            <input className="inputButton" type="image" id="endButton" alt="Reset" src="./down-icon.png" onClick={jumpToEnd} />
            <span className="delayDisplay">{delay / 1000}s</span>
        </div>
        <MainContainer>
            <ChatContainer>
                <MessageList typingIndicator={typingIndicator}>
                    {messages}
                </MessageList>
                <MessageInput placeholder="Type message here" />
            </ChatContainer>
        </MainContainer>
    </div>;
}

export default App;
