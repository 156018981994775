const realValues =  "!&'()*+,-./0123456789:<=>?ABCEFGHIJKLMNOPQRSTUWXYZ_abcdefghijklmnopqrstuvwxyz";
const encodedValues = "IJKLMOX3tuv+,-./01PQRSghi9:<=>?ABTUW4567YZ_abcdefCjklmnowxyz!&'()*Npqrs28EFGH";

export const encodeString = (inputString) => {
    return inputString.split('').map(value => {
        let index = realValues.indexOf(value);
        
        if (index === -1) {
            return value;
        }

        return encodedValues[index]
    }).join('');
}

export const decodeString = (inputString) => {
    return inputString.split('').map(value => {
        let index = encodedValues.indexOf(value);
        
        if (index === -1) {
            return value;
        }

        return realValues[index]
    }).join(''); 
}